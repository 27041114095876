import React, { ReactNode } from 'react';
import { cn } from '@/util/styleUtils';

export type AlertType = 'error' | 'info' | 'success' | 'warning';
type Props = { level?: AlertType; className?: string; title?: ReactNode } & (
    | { children: ReactNode; message?: never }
    | { message: ReactNode; children?: never }
);
const Alert = ({ children, level = 'error', className, title, message }: Props) => {
    return (
        <div
            className={cn(
                'mb-4 rounded border-l-4 px-4 py-4 last:mb-0',
                {
                    'border-indigo-500 bg-indigo-100 text-indigo-700': level === 'info',
                    'border-red-500 bg-red-200 text-red-700': level === 'error',
                    'border-emerald-500 bg-emerald-100 text-emerald-700': level === 'success',
                    'border-amber-500 bg-amber-200 text-amber-700': level === 'warning',
                },
                className
            )}
            role="alert"
        >
            {title && <div className="font-bold">{title}</div>}
            <div className="">{message ?? children}</div>
        </div>
    );
};

export default Alert;
