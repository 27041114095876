import { Subscriber } from '@/models/Subscriber';
import Order from '@/models/Order';
import { Menu } from '@/models/Menu';
import { formatDateStringWithOffsetDays, getDeliveryDayOffset } from '@/util/DateUtil';
import { addDays, addMinutes } from 'date-fns';

export enum DayOfWeek {
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
}

export type DeliveryDay = DayOfWeek;
type DayNameMap = { [key in DeliveryDay]: string };

export const DEFAULT_DELIVERY_DAY = DayOfWeek.sunday;

export const DeliveryDaySortMap: Record<DayOfWeek, number> = {
    [DayOfWeek.friday]: 1,
    [DayOfWeek.saturday]: 2,
    [DayOfWeek.sunday]: 3,
    [DayOfWeek.monday]: 4,
    [DayOfWeek.tuesday]: 5,
    [DayOfWeek.wednesday]: 6,
    [DayOfWeek.thursday]: 7,
};

export const getDayOfWeekFromString = (input?: string): DayOfWeek | null => {
    switch (input?.toLowerCase().trim()) {
        case 'sunday':
            return DayOfWeek.sunday;
        case 'monday':
            return DayOfWeek.monday;
        case 'tuesday':
            return DayOfWeek.tuesday;
        case 'wednesday':
            return DayOfWeek.wednesday;
        case 'thursday':
            return DayOfWeek.thursday;
        case 'friday':
            return DayOfWeek.friday;
        case 'saturday':
            return DayOfWeek.saturday;
        default:
            return null;
    }
};

export const dayOfWeekComparator = (i1: string, i2: string) => {
    const d1 = getDayOfWeekFromString(i1);
    const d2 = getDayOfWeekFromString(i2);
    if (d1 === null || d2 === null) {
        return (i1 ?? '').localeCompare(i2 ?? '');
    }
    return deliveryDayComparator(d1, d2);
};

export const deliveryDayComparator = (d1: DayOfWeek, d2: DayOfWeek) => {
    return DeliveryDaySortMap[d1] - DeliveryDaySortMap[d2];
};

export const sortedAllDeliveryDays = [
    DayOfWeek.friday,
    DayOfWeek.saturday,
    DayOfWeek.sunday,
    DayOfWeek.monday,
    DayOfWeek.tuesday,
    DayOfWeek.wednesday,
    DayOfWeek.thursday,
];

export const DayOfWeekMap: DayNameMap = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
};

export const dayOfWeekName = (day: DayOfWeek) => {
    return DayOfWeekMap[day];
};

export const getDeliveryDay = (order: {
    delivery_day?: DeliveryDay | null;
    subscriber?: Partial<Subscriber> | null;
}) => {
    const dayOfWeek: DayOfWeek = Number(order.delivery_day ?? order.subscriber?.delivery_day ?? DEFAULT_DELIVERY_DAY);

    return DayOfWeekMap[dayOfWeek] ?? DayOfWeekMap[DayOfWeek.sunday];
};
export const getOrderDeliveryDayOfWeek = (params: { order: Order; subscriber?: Subscriber | null }): DayOfWeek => {
    const { order, subscriber } = params;

    const subscriberDeliveryDay = Number(subscriber?.delivery_day ?? DEFAULT_DELIVERY_DAY);

    return Number(order.delivery_day ?? subscriberDeliveryDay);
};

export const getOrderDeliveryDate = (params: { order: Order; subscriber?: Subscriber | null; menu: Menu }): Date => {
    const { order, subscriber, menu } = params;

    if (order.delivery_completed_at) {
        return new Date(order.delivery_completed_at);
    }

    const date = new Date(menu.menu_date);
    const dayOfWeek = getOrderDeliveryDayOfWeek({ order, subscriber });

    const dateWithTZ = addMinutes(date, date.getTimezoneOffset());
    const offsetDays = getDeliveryDayOffset(dayOfWeek);
    return addDays(dateWithTZ, offsetDays);
};

export const getOrderDeliveryDateString = (params: {
    order: Order;
    subscriber?: Subscriber | null;
    format: string;
    menu: Menu;
}): string => {
    const { order, subscriber, menu, format } = params;
    const dayOfWeek = getOrderDeliveryDayOfWeek({ order, subscriber });
    return formatDateStringWithOffsetDays(menu.menu_date, getDeliveryDayOffset(dayOfWeek), format);
};
