import React, { ReactNode, useId } from 'react';
import { cn } from '@/util/styleUtils';

type Props = { label?: ReactNode } & React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
> & { fullWidth?: boolean };

function NativeSelect({ label, children, className, fullWidth, ...selectProps }: Props) {
    const autoId = useId();
    return (
        <div className={cn({ 'w-full': fullWidth })}>
            {label && (
                <label htmlFor={selectProps.id ?? autoId} className="block text-sm font-medium leading-6 text-gray-900">
                    {label}
                </label>
            )}
            <select
                id={selectProps.id ?? autoId}
                className={cn(
                    'form-select block h-10 w-full rounded-md border border-input bg-background py-2 pl-3 pr-8 text-sm text-gray-900',
                    'focus-visible:outline-none focus-visible:ring-2  focus-visible:ring-ring focus-visible:ring-offset-2',
                    'ring-offset-background focus-visible:ring-offset-2',
                    'outline-none focus-visible:border-border disabled:cursor-not-allowed disabled:opacity-50',
                    className
                )}
                {...selectProps}
            >
                {children}
            </select>
        </div>
    );
}

export default NativeSelect;
