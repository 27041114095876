import { cn } from '@/util/styleUtils';
import { LoaderCircleIcon } from 'lucide-react';

export type SpinnerProps = {
    className?: string;
    size?: 'sm' | 'md' | 'lg';
};
export const Spinner = ({ className, size = 'md' }: SpinnerProps) => {
    return (
        <LoaderCircleIcon
            className={cn(`animate-spin text-orange`, className, {
                'h-4 w-4': size === 'sm',
                'h-6 w-6': size === 'md',
                'h-14 w-14': size === 'lg',
            })}
        />
    );
};
