import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';

import { cn } from '@/util/styleUtils';
import { ReactNode, useId } from 'react';

export type CheckedState = CheckboxPrimitive.CheckedState;

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
        label?: ReactNode;
        error?: boolean;
        align?: 'top' | 'center';
    }
>(({ className, label, align = 'center', error, id: _id, ...props }, ref) => {
    const generatedId = useId();
    const id = _id ?? generatedId;
    const $checkbox = (
        <CheckboxPrimitive.Root
            ref={ref}
            className={cn(
                'peer h-5 w-5 shrink-0 rounded-sm border border-ring ring-offset-background',
                'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                'disabled:cursor-not-allowed disabled:opacity-50',
                ' data-[state=checked]:text-primary',
                { 'border-red-500 ring-red-500': error },
                className
            )}
            id={id}
            {...props}
        >
            <CheckboxPrimitive.Indicator
                className={cn('flex justify-center text-current', {
                    'items-start': align === 'top',
                    'items-center': align === 'center',
                })}
            >
                <Check className="h-5 w-5" strokeWidth={4} />
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
    );

    if (label) {
        return (
            <div
                className={cn('flex space-x-2', {
                    'text-red-500': error,
                    'items-start': align === 'top',
                    'items-center': align === 'center',
                })}
            >
                {$checkbox}
                <label
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    htmlFor={id}
                >
                    {label}
                </label>
            </div>
        );
    }
    return $checkbox;
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
