import { addDays, addMinutes, format, isPast } from 'date-fns';
import { DayOfWeek } from '@/models/DeliveryDay';
import { isDefined } from '@/util/TypeGuards';
import DeliveryWindow from '@/models/DeliveryWindow';
import Order from '@/models/Order';

export const DateFormat = {
    short: 'MM/dd/y',
    casual: 'M/d/y',
    medium: 'MMMM do',
    long: 'MMMM do, yyy',
    dayOfWeek: 'EEEE, MMMM do',
    dayOfWeekShort: 'EEEE, MMM do',
    dayTimeOfWeek: "EEEE, MMMM do 'at' h:mm a",
    dayTimeOfWeekShort: "EEE, MMM do 'at' h:mm a",
    dayOfWeekDateTimeShort: "EEE, M/d/yyy 'at' h:mm a",
    dateTimeLong: "MMMM do, yyy 'at' h:mm a",
    timeLong: 'h:mm a',
    dateTimeShort: "M/d/y 'at' h:mm a",
    isoDate: 'yyy-MM-dd',
    isoDateTime: "yyy-MM-dd 'at' hh:mm a",
    hoursAndMinutes: 'h:mm a',
    monthDayShort: 'MMM d',
};

/**
 * Format a date according to the user's local timezone
 */
export const formatDate = (input: Date | string, formatting = 'MM/dd/y', addTimezone = true) => {
    const date = new Date(input);
    const withTz = addTimezone ? addMinutes(date, date.getTimezoneOffset()) : date;
    return format(withTz, formatting);
};

export const formatDateString = (input: string, formatting?: string, addTimezone = true) => {
    // const addTimezone = !input.endsWith('Z')
    const date = new Date(input);
    return formatDate(date, formatting, addTimezone);
};

export const formatDateStringWithOffsetDays = (input: string, offsetDays: number, formatting?: string) => {
    const date = new Date(input);
    return formatDateWithOffsetDays(date, offsetDays, formatting);
};

export const formatDateWithOffsetDays = (date: Date, offsetDays: number, formatting = 'MM/dd/y') => {
    const dateWithTZ = addMinutes(date, date.getTimezoneOffset());
    return format(addDays(dateWithTZ, offsetDays), formatting);
};

export const isOlderThanDays = (input: string, days: number): boolean => {
    const date = new Date(input);
    const offsetDate = addDays(date, days);
    return isPast(offsetDate);
};

/**
 * Get the offset in days relative to a Menu Date  for a given Delivery Day
 * @param {DayOfWeek} deliveryDay [DayOfWeek=saturday]
 * @return {number}
 */
export const getDeliveryDayOffset = (deliveryDay: DayOfWeek | null = DayOfWeek.saturday): number => {
    switch (deliveryDay) {
        case DayOfWeek.monday:
            return 1;
        case DayOfWeek.tuesday:
            return 2;
        case DayOfWeek.wednesday:
            return 3;
        case DayOfWeek.thursday:
            return -3;
        case DayOfWeek.friday:
            return -2;
        case DayOfWeek.saturday:
            return -1;
        case DayOfWeek.sunday:
        default:
            return 0;
    }
};

/**
 * Get the date and time that ordering is cut off for a given menu date
 * @param {string | null} menuDate
 * @return {string}
 */
export const getFormattedOrderDeadline = (menuDate?: string | null) => {
    if (!isDefined(menuDate)) {
        return '';
    }
    return `${formatDateStringWithOffsetDays(menuDate, -7, DateFormat.dayOfWeek)} at 11:59 pm`;
};

export const getFormattedWeekName = (menuDate: string) => {
    return formatDateString(menuDate, DateFormat.long);
};

export const getFormattedRelativeDay = (menuDate: string, offsetDays: number) => {
    return formatDateStringWithOffsetDays(menuDate, offsetDays, DateFormat.dayOfWeek);
};

/**
 * for a given Time, format it to human-readable AM/PM.
 * @param {string} time HH:MM:SS
 */
export const formatTime = (time: string) => {
    return new Date(`2022-01-01T${time}`).toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
    });
};

/**
 * Get a human-readable delivery window, such as `between 9am and 5pm` or `by 8am`.
 * If the `deliver_after` time is midnight (00:00:00) then the start time is omitted
 * @param {DeliveryWindow | null} deliveryWindow
 * @param {Order|null} order
 * @returns {string | null}
 */
export const formatDeliveryWindow = ({
    deliveryWindow,
    order,
}: {
    deliveryWindow?: Pick<DeliveryWindow, 'deliver_before' | 'deliver_after' | 'display_name'> | null;
    order?: Order | null;
}): string | null => {
    const beforeTime = order?.deliver_before_override ?? deliveryWindow?.deliver_before;
    const afterTime = order?.deliver_after_override ?? deliveryWindow?.deliver_after;
    if (!beforeTime || !afterTime) {
        return null;
    }

    const before = formatTime(beforeTime);
    const after = formatTime(afterTime);

    return `${deliveryWindow?.display_name} (between ${after} and ${before})`;
};
