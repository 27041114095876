import { ErrorMessage } from 'formik';
import { cn } from '@/util/styleUtils';
import React from 'react';

type Props = { name: string; className?: string };
const FieldError = ({ name, className }: Props) => {
    return <ErrorMessage name={name} className={cn('text-sm text-red-500', className)} component={'p'} />;
};

export default FieldError;
