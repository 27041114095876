import { isNotBlank } from '@/util/TypeUtil';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { cn } from '@/util/styleUtils';
import { ReactNode } from 'react';

export type TagColor = 'green' | 'red' | 'gray' | 'orange' | 'blue';
type Props = {
    subtitle?: string | null;
    className?: string;
    onClose?: () => void;
    color?: TagColor | null;
} & ({ title: string; children?: never } | { title?: never; children: ReactNode });
const TagView = ({ title, subtitle, className, onClose, children, color = 'green' }: Props) => {
    return (
        <div
            className={cn(
                'mb-1 mr-2 inline-flex flex-row items-center whitespace-nowrap rounded-xl px-2 py-0.5 text-center text-sm text-dark-grey',
                className,
                {
                    'pl-2': !!onClose,
                    'bg-green-light/30': color === 'green',
                    'bg-gray-200': color === 'gray',
                    'bg-red-200': color === 'red',
                    'bg-indigo-200 text-indigo-600': color === 'blue',
                    'bg-orange/20 text-dark-orange': color === 'orange',
                }
            )}
        >
            <div className="flex items-center">
                <div className="flex flex-col">
                    <span className="font-semibold">{title ?? children}</span>
                    {isNotBlank(subtitle) && <span className="-mt-1 px-1 text-xs">{subtitle}</span>}
                </div>
                {onClose && (
                    <div className="ml-1">
                        <div className="-mr-1 flex">
                            <span
                                className="cursor-pointer rounded-full p-0.5 hover:bg-gray-50 hover:bg-opacity-50"
                                onClick={() => onClose()}
                            >
                                <XCircleIcon height={14} />
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TagView;
