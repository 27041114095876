import React, { HTMLProps, PropsWithChildren, ReactNode } from 'react';
import { cn } from '@/util/styleUtils';

export type Props = PropsWithChildren<
    { text?: ReactNode | null; className?: string; noMargin?: boolean } & HTMLProps<HTMLLabelElement>
>;
const FieldLabel = (props: Props) => {
    const { text, className, noMargin, children, ...rest } = props;
    return (
        <label
            className={cn(
                ' block text-base font-semibold leading-none text-foreground peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
                { 'mb-2': !noMargin },
                className
            )}
            {...rest}
        >
            {text ?? children}
        </label>
    );
};

export default FieldLabel;
