import React, { HTMLProps, ReactNode } from 'react';
import { Field, FieldAttributes, FieldProps, useField } from 'formik';
import Input from '@/components/forms/controls/Input';
import { Textarea } from '@/components/ui/textarea';
import NativeSelect from '@/components/ui/native-select';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/util/styleUtils';

export type Props = {
    name: string;
    fullWidth?: boolean;
    default?: string;
    text?: ReactNode;
    type?: string;
} & FieldAttributes<any> & // eslint-disable-line @typescript-eslint/no-explicit-any
    HTMLProps<HTMLInputElement> &
    FieldProps;
const FormikInput = ({ name, as = 'input', className, fullWidth = false, ...rest }: Props) => {
    const [field, meta, helpers] = useField<boolean>(name);
    const hasError = !!meta.error && meta.touched;

    const classList = cn(className, {
        'border-destructive': hasError,
        'w-full': fullWidth,
    });

    let comp = as;

    if (as === 'checkbox' || rest.type === 'checkbox' || as === Checkbox) {
        return (
            <Checkbox
                checked={field.value}
                id={rest.id}
                name={name}
                onCheckedChange={(checked) => helpers.setValue(checked === 'indeterminate' ? false : checked)}
                error={hasError}
                label={rest.text}
            />
        );
    } else if (as === 'textarea') {
        comp = Textarea;
    } else if (as === 'select') {
        comp = NativeSelect;
    } else if (as === 'input') {
        comp = Input;
    }

    return (
        <Field
            name={name}
            className={classList}
            as={comp}
            {...rest}
            value={field.value !== null ? String(field.value) : ''}
        />
    );
};

export default FormikInput;
