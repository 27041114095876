import React from 'react';
import { cn } from '@/util/styleUtils';
import { cva, VariantProps } from 'class-variance-authority';

export const baseClassNames =
    'text-base px-3 py-2 w-full rounded-md border border-input bg-background placeholder:text-muted-foreground/60 ring-offset-background';
export const inputClassNames = cn(
    baseClassNames,
    'h-10',
    'flex',
    'file:border-0 file:bg-transparent file:text-sm file:font-medium',
    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
    'disabled:cursor-not-allowed disabled:opacity-50'
);

const inputVariants = cva(inputClassNames, {
    variants: {
        variant: { error: 'border-destructive', default: '' },
    },
});

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, variant, ...props }, ref) => {
    return <input type={type} className={cn(inputVariants({ variant }), className)} ref={ref} {...props} />;
});
Input.displayName = 'Input';

export { Input };

export default Input;
