import { isDefined } from '@/util/TypeGuards';
import { Dispatch, SetStateAction } from 'react';

export type Nullable<T> = T | null;
export type Optional<T> = T | null | undefined;
export type OptionalString = Optional<string>;
export type OmitAudit<T> = Omit<T, 'created_at' | 'updated_at' | 'created_by' | 'updated_by'>;
export type CreatableModel<T> = Omit<T, 'created_at' | 'updated_at' | 'created_by' | 'updated_by' | 'id'>;
export type StateSetter<T> = Dispatch<SetStateAction<T>>;
export type StringWithAutocomplete<T extends string> = T | (string & Record<never, never>);
export type RequiredNotNull<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};

export type Ensure<T, K extends keyof T> = T & RequiredNotNull<Pick<T, K>>;

export type JSONValue = string | number | boolean | { [x: string]: JSONValue | Array<JSONValue> };
export type JSONArray = Array<JSONValue>;

export function isBlank(input?: OptionalString): boolean {
    if (!input) {
        return true;
    }
    return input.trim().length === 0;
}

export function isPresent<T>(value: T | null | undefined): value is T {
    return value !== undefined && value !== null;
}

export function isNotBlank(input?: OptionalString): input is string {
    return !isBlank(input);
}

export function blankToNull(input?: string | null | undefined): string | null {
    const i = input ?? null;
    return isBlank(i) ? null : i;
}

export function stringToNumber(input: string | null | undefined): Nullable<number> {
    if (isBlank(input)) {
        return null;
    }
    const num = Number(input);
    if (isNaN(num)) {
        return null;
    }
    return num;
}

export function stringToBoolean(input?: string | null | undefined): boolean {
    if (isBlank(input)) {
        return false;
    }
    return input?.toLowerCase() === 'true';
}

export function isEmptyObject(obj: Record<string | number | symbol, unknown>): boolean {
    return Object.keys(obj).length === 0;
}

export function isNotEmptyObject(obj: Record<string | number | symbol, unknown>): boolean {
    return Object.keys(obj).length > 0;
}

export function valueOrFirst<T>(input?: T | T[] | null, defaultValue?: T): T | null {
    if (!isDefined(input)) {
        return defaultValue ?? null;
    }
    if (Array.isArray(input)) {
        return input[0] ?? defaultValue ?? null;
    }
    return input ?? defaultValue ?? null;
}

export function isApiCallError(input: unknown): boolean {
    if (!isDefined(input)) {
        return false;
    }
    return (input as Record<string, string>).code === 'APICallError';
}

export type TimeoutType = ReturnType<typeof setTimeout>;
