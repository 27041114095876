import { ReactNode } from 'react';
import { Spinner, SpinnerProps } from '@/components/Spinner';
import { cn } from '@/util/styleUtils';

type Props = { message?: ReactNode; children?: ReactNode; spinnerSize?: SpinnerProps['size']; className?: string };
const LoadingMessage = ({ message, spinnerSize, children, className }: Props) => {
    return (
        <div className={cn('flex items-center space-x-2', className)}>
            <Spinner size={spinnerSize} />
            <div>{message ?? children}</div>
        </div>
    );
};

export default LoadingMessage;
